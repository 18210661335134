// Agenda & Teaser
// =================

/* Teaser
-------------------------------------*/

.main-teaser__container {
  height: auto;
  overflow: hidden;
  position: relative;
  top: 0;
  @include breakpoint(large) {
    min-height: 20rem;
    display: flex;
  }
}

.main-teaser__container--first {
  @include breakpoint(large) {
    margin-bottom: 1.25rem;
  }
  margin-bottom: 1.25rem;
}

.main-teaser__textbox {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $blue;
  padding: 1.875rem;
  color: $white;
  height: auto;
  width: 100%;
  opacity: 1;
  z-index: 2;
  transition: background-color 0.3s;

  @include breakpoint(large) {
    width: 50%;
    background-color: $blue-alpha;
  }

  &:hover {
    background-color: $blue;
  }
  ul {
    padding-left: 1rem;
    li {
      font-size: $main-font-size;
      line-height: 1.5625rem;
    }
  }

  h2 {
    @include breakpoint(medium) {
      white-space: nowrap;
    }
  }

  .teaser-button {
    width: fit-content;
  }
}

.main-teaser__image-right {
  display: none;
  @include breakpoint(large) {
    display: block;
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: "object-fit: cover";
    }
  }
  /*  .main-teaser__image-translate {
    transform: translate(0, -30%);
  }*/
}

/* Agenda
-------------------------------------*/

.agenda {
  @include breakpoint(medium) {
    padding-left: 1.25rem;
  }
}

.agenda__container {
  padding: 1.1rem 1.25rem;
  border-top: none;
  border-bottom: none;
  height: 100%;
  @include breakpoint(medium only) {
    padding: 1.875rem 2.8125rem;
  }
  @include breakpoint(large) {
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
  }
}

.agenda-icon {
  height: 44px;
  width: 44px;
  margin-bottom: auto;
  transition: 0.2s ease-in-out;
}

.agenda-grid {
  display: flex;
  //flex-wrap: wrap;
  padding: 1.1rem 0;

  &:hover .agenda-icon {
    transform: rotate(-45deg);
  }
  p {
    margin-bottom: 0;
  }
}

.agenda-icon__container {
  flex: 1 1 15%;
}
.agenda__text {
  flex: 1 1 85%;
  margin-left: 0.75rem;

  @include breakpoint(medium) {
    margin-left: 1.25rem;
  }
}

.teaser-button {
  font-weight: bold;
  height: auto;
  text-align: center;
  vertical-align: middle;
  border: 2px solid $white;
  transition: 0.3s;
  font-size: $main-font-size-small;
  padding: 0.3125rem 1.875rem;
  &:hover {
    background-color: $white;
    color: $black;
  }
}

;@import "sass-embedded-legacy-load-done:128";