// Header
// =================

.logo-container {
  position: relative;
  width: 13.3rem;
}

.sfr-logo {
  height: auto;
  width: 8rem;
  transform: translateY(-10%);

  @include breakpoint(large) {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 13.3rem;
    transform: translateY(-49%);
  }
}

.header {
  display: block;
  line-height: normal;
}

.navigation {
  top: 0;
  width: 100%;
  position: fixed;
  box-shadow: $box-shadow-soft;
  background: $white;
  transition: 0.2s ease box-shadow;
  z-index: 1000000;
  padding: 0.5rem 0;
  @include breakpoint(large) {
    padding: 0;
  }

  a {
    font-size: $nav-font-size-tiny;
  }
  .scrolled & {
    box-shadow: $box-shadow-hard;
  }

  &__arrows:before {
    content: url("img/angle.svg");
  }

  &__arrows {
    transition: 0.2s ease-in-out;
    position: absolute;
    right: 1.875rem;
    @include breakpoint(large) {
      display: none;
    }
  }

  &--data {
    &-menu {
      left: 0;
      right: 0;
      top: 100%;
    }
  }
}

.navigation__main {
  max-width: none;
  @include breakpoint(large) {
    display: flex;
    justify-content: space-between;
  }
}

.navigation__main__menu {
  display: inline-block;
  position: relative;
  text-align: left;
  z-index: 900;
  :hover {
    color: $active-color;
  }
  &-toggle {
    @include user-select(none);
    position: relative;
    vertical-align: top;
    text-decoration: none;
    font-size: $main-font-size-small;
    font-weight: bold;
    color: $white;
    padding: 1.25rem 2.5rem 1.25rem 1.875rem;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
    border-bottom: 0.025rem solid $white;

    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        a {
          font-size: $nav-font-size;
        }
      }
    }

    .w--open {
      border-bottom-color: #fff;
      background-image: linear-gradient(180deg, $black, $black);
      color: #fff;
    }
    @include breakpoint(large) {
      color: $black;
      padding: 1.32rem 1rem;
    }
    @include breakpoint(medium down) {
      padding: 1.32rem 1.25rem;
    }
  }
}

.navigation__login {
  display: none;
  margin-left: auto;
  @include breakpoint(large) {
    display: block;
  }
}

.navigation__login__items {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background-color: $white;
  color: $blue;
  margin: 0 0 0 auto;
  padding: 0.25rem 0;
  font-size: 12px;
  font-weight: 700;
  font-family: $font-main;

  a {
    font-size: 12px;
    margin-right: 0.3rem;
  }
}

.navigation-meta__language {
  margin-right: 1rem;
}

.navigation-meta__bottom {
  display: flex;
  align-items: center;
  align-content: center;
}

.navigation-meta__top {
  display: flex;
  margin-bottom: 0.75rem;
}

.navigation__login-small {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: stretch;
  flex: 0 0 auto;
  background-color: $blue;
  z-index: 10;
  padding: 1.313rem 1.875rem 1.375rem 1.313rem;
  margin-left: auto;
  @include breakpoint(large) {
    display: none;
  }
  a {
    font-family: $font-main;
    font-size: 12px;
  }

  form {
    padding: 0;
    height: 1.25rem;
    color: $white;
    font-size: 12px;
    margin-left: 0 !important;
    font-family: $font-main;
  }

  form,
  a {
    z-index: 10;
  }

  a {
    margin-right: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: $white;
    text-align: left;

    &:first-of-type {
      margin-left: auto;
    }
    &:last-of-type {
      margin-left: 0.625rem;
    }
  }
}

.login--menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.icons--menu {
  .icon {
    margin-left: 0.6rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.navigation__main__menu:hover .navigation__main__menu-list {
  @include breakpoint(medium up) {
    width: 13rem;
    display: block !important;
  }
  &.is--open {
    display: block !important;
  }
}

.navigation__main__menu:hover .navigation__arrows {
  &.rotate {
    transform: rotate(180deg);
  }
}

.navigation__main__menu-list {
  position: absolute;
  display: none;
  min-width: 100%;
  color: #fff;
  background-color: $blue;
  height: auto;

  a {
    font-size: $nav-font-size;
  }
  &:hover {
    color: #ffb401 !important;
  }
  @include breakpoint(large) {
    margin-top: 0;
    padding-bottom: 1.875rem;
    background-color: $blue-alpha;
    transition: background-color 0.35s;
    height: auto;
    padding-top: 1.875rem;

    &:hover {
      background-color: $blue;
    }
  }
  @include breakpoint(medium down) {
    a {
      text-indent: 1.5em;
    }
    :last-child {
      border-bottom: 1px solid #fff;
    }
  }
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $medium;
}

.w-nav-button {
  float: right;
  padding: 1.125rem;
  font-size: 24px;
  display: none;
  cursor: pointer;
  tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}

body {
  &.nav-is-open {
    position: fixed;
  }
  //-webkit-overflow-scrolling: touch;
}

.navigation--data-menu {
  line-height: 20px;
  @include breakpoint(medium down) {
    height: calc(100vh - 9rem);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 120000;
  }
}

@include breakpoint(medium down) {
  .navigation--data[data-collapse="small"] {
    .navigation--data-menu {
      display: none;
    }
    .w-nav-button,
    .navigation__main__menu,
    .navigation__main__menu-toggle {
      display: block;
    }
    .navigation__main__menu-list {
      position: static;
    }
  }
}

.brand {
  margin-right: 2.5rem;
  background-position: 52% 51%;
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.nav-auth {
  margin-right: 1.25rem !important;
  margin-left: 0 !important;
}

.dropdown-list.w--open {
  .scrolled & {
    height: auto;
  }
}

// burgermenu

.button_container {
  cursor: pointer;
  z-index: 1000001;
  transition: opacity 0.25s ease;
  transition: 250ms ease top;
}

.overlay {
  position: fixed;
  background: $blue;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.15s,
    visibility 0.15s,
    height 0.15s;
  overflow: hidden;
  display: inherit;
  @include breakpoint(large) {
    display: none;
  }
}

.menu-button:hover {
  opacity: 0.7;
}
.button_container.active-burger .top {
  -webkit-transform: translateY(11px) translateX(0) rotate(45deg);
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #fff;
}
.button_container.active-burger .middle {
  opacity: 0;
  background: #fff;
}
.button_container.active-burger .bottom {
  -webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #fff;
}
.button_container span {
  background: $black;
  border: none;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  cursor: pointer;
}
.button_container span:nth-of-type(2) {
  top: 11px;
}
.button_container span:nth-of-type(3) {
  top: 22px;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

@include breakpoint(medium down) {
  .menu-button {
    position: absolute;
    right: 1.25rem;
    bottom: 0.625rem;
    display: block;
    padding-top: 1.3rem;
    clear: none;
    justify-content: flex-start;
    align-items: stretch;
    flex: 0 auto;
  }
  .menu-button {
    padding-top: 1rem;
  }
  .menu-button.w--open {
    background-color: $black;
  }
}

.navigation__main__menu-list.w--open {
  display: block;
}
.navigation__main__menu-link {
  padding: 0.625rem 1.25rem;

  display: block;
  color: #fff;
}
.navigation__main__menu-link.w--current {
  color: $active-color;
}

.w--nav-menu-open {
  display: block !important;
  position: absolute;
  background: $blue;
}
.w--nav-link-open {
  display: block;
  position: relative;
}
.w-nav-overlay {
  position: absolute;
  overflow: hidden;
  display: none;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
}
.w-nav-overlay .w--nav-menu-open {
  top: 0;
}

.sfr-button--menu {
  font-size: 14px !important;
  margin: 0 0 0 0.5rem !important;

  .icon {
    margin-right: 0;
    margin-left: 0.5rem !important;
  }
}

.sfr-button--mobile {
  font-size: 14px !important;
  margin-left: 0 !important;
  width: fit-content;
  margin-top: 1rem;

  .icon {
    margin-right: 0;
    margin-left: 0.5rem !important;
  }
}

;@import "sass-embedded-legacy-load-done:117";