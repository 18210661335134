/* cyrillic-ext */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/notosans/v30/o-0IIpQlx3QUlC5A4PNr6DRASf6M7VBj.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/notosans/v30/o-0IIpQlx3QUlC5A4PNr4TRASf6M7VBj.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/notosans/v30/o-0IIpQlx3QUlC5A4PNr5DRASf6M7VBj.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
/* greek-ext */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/notosans/v30/o-0IIpQlx3QUlC5A4PNr6TRASf6M7VBj.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/notosans/v30/o-0IIpQlx3QUlC5A4PNr5jRASf6M7VBj.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/notosans/v30/o-0IIpQlx3QUlC5A4PNr6jRASf6M7VBj.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/notosans/v30/o-0IIpQlx3QUlC5A4PNr6zRASf6M7VBj.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  src: url(fonts/notosans/v30/o-0IIpQlx3QUlC5A4PNr5TRASf6M7Q.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/notosans/v30/o-0NIpQlx3QUlC5A4PNjXhFVadyBx2pqPIif.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/notosans/v30/o-0NIpQlx3QUlC5A4PNjXhFVYNyBx2pqPIif.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* devanagari */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/notosans/v30/o-0NIpQlx3QUlC5A4PNjXhFVZdyBx2pqPIif.woff2)
    format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC,
    U+A830-A839, U+A8E0-A8FF;
}
/* greek-ext */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/notosans/v30/o-0NIpQlx3QUlC5A4PNjXhFVaNyBx2pqPIif.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/notosans/v30/o-0NIpQlx3QUlC5A4PNjXhFVZ9yBx2pqPIif.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/notosans/v30/o-0NIpQlx3QUlC5A4PNjXhFVa9yBx2pqPIif.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/notosans/v30/o-0NIpQlx3QUlC5A4PNjXhFVatyBx2pqPIif.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  src: url(fonts/notosans/v30/o-0NIpQlx3QUlC5A4PNjXhFVZNyBx2pqPA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
