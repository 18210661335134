@import "node_modules/foundation-sites/scss/util/util";

$global-width: rem-calc(1240);

@import "node_modules/foundation-sites/scss/foundation";

$global-flexbox: true;

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 940px,
  xlarge: 1200px,
  xxlarge: 1440px,
);

$breakpoint-classes: (small medium large);

$grid-margin-gutter: (
  small: 1rem,
  medium: 1rem,
);

@include foundation-xy-grid-classes;
@include foundation-responsive-embed;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-text-alignment;

.medium-align-left {
  @include breakpoint(medium up) {
    justify-content: flex-start;
  }
}

.large-align-left {
  @include breakpoint(large up) {
    justify-content: flex-start;
  }
}

.cell {
  @include breakpoint(small only) {
  }
}

;@import "sass-embedded-legacy-load-done:0";