.faq-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.js-foldout-wrapper {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.faq-list {
  margin-bottom: 4rem;
}

.faq-margin {
  margin: 0 0.38rem 3rem 0.38rem;

  @include breakpoint(small only) {
    margin: 0 1.5rem 2rem 1.5rem;
  }
}

.faq-column {
  display: table;
  border-collapse: collapse;
  width: 100%;
}

.faq {
  border: 0px solid $black;
  border-top-width: 1px;

  width: 100%;
  display: table-row;
  border-bottom-width: 1px;
}
.arrow--vertical {
  color: $blue;
}
.faq__header {
  padding: 0.4rem 0;

  display: block;
  p {
    margin: 0 0 1rem 0;
  }
}

.expanded__button {
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:focus,
  &:active {
    outline: none;
  }
  border: 2px solid $active-color;
  transition: all 0.3s ease;
  font-size: 15px;
  padding: 5px 20px 5px 10px;
  &:hover {
    background-color: $active-color;
    color: $white;
    &::before {
      content: "";
      padding: 1rem;
      vertical-align: middle;
      background: url("img/angle.svg") center / 50% no-repeat;
      transition:
        transform 200ms ease-in-out,
        background 300ms ease;
      .toggled & {
        transform: rotate(-180deg);
      }
    }
  }

  &::before {
    content: "";
    padding: 1rem;
    vertical-align: middle;
    background: url("img/angle-black.svg") center / 50% no-repeat;
    transition: transform 200ms ease-in-out;
    .toggled & {
      transform: rotate(-180deg);
    }
  }
}

.faq__contents {
  border: 0px solid $black;
  border-top-width: 1px;
  padding: 1rem 0 0.1rem 0;
  .snippet--small {
    padding: 0;
  }
}

.faq-title {
  margin: 1rem 0;
}

;@import "sass-embedded-legacy-load-done:130";