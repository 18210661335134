// News Slider
// =================

.news-slider {
  .news__wrapper {
    overflow: hidden;
    position: relative;
    .news-slider--mask {
      display: flex;
      transition: transform 0.5s ease-in-out;

      .news-slide__container {
        display: block;
      }
      .news-slide {
        display: flex;
        padding: 0 1.5rem;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:126";