// Newsletter & Member
// =================

/* Gallery
-------------------------------------*/

.card__container {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: rem-calc(200px);

  @include breakpoint(medium) {
    height: rem-calc(290px);
  }

  &.card__container--large {
    @include breakpoint(medium) {
      height: rem-calc(300px);
    }
    @include breakpoint(large) {
      height: rem-calc(380px);
    }
  }
  transition: transform 0.5s ease-in-out;
  &:hover .image-opacity {
    opacity: 0.7;
  }
  &:hover .card__icon,
  &:hover .card__icon--big {
    transform: rotate(-45deg);
  }
}

.card-section--hover {
  transition: 0.2s ease-in-out;
  .paragraph-title {
    transition: 0.2s ease-in-out;
  }
  &:hover,
  &:hover .paragraph-title {
    color: $active-color;
  }
}

.card__image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.card__image {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.card__short {
  margin-top: 1rem;
}

.image-opacity {
  position: absolute;
  top: 0;
  background: $black;
  opacity: 0.5;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: background 0.2s ease-in-out;
}
.card__icon-position {
  position: relative;
  margin-bottom: 0.5rem;
}
.card__title {
  position: relative;
  text-align: center;
  width: 100%;
}

.card__icon {
  transition: transform 200ms ease-in-out;
  width: 4rem;
  height: 4rem;
}
.card__icon--big {
  width: 5rem;
  height: 5rem;
}

/* Gallery Slider
-------------------------------------*/

.group-teaser {
  background: $white;

  .group-teaser__wrapper {
    overflow: hidden;
    position: relative;

    .group-teaser__slider {
      display: flex;
      transition: transform 0.5s ease-in-out;

      .group-teaser__slide {
        position: relative;
        background: $white;
        display: flex;
        flex-direction: column;
        padding-right: 5px;
        padding-left: 1px;
        @include breakpoint(medium) {
          padding-right: 1.25rem;
          padding-left: 0;
        }
      }
    }
  }
}

.group-teaser__link {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.group-teaser__controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0;
}

.group-teaser__button {
  flex: 1 0 auto;
  text-align: center;
  text-transform: uppercase;
}

.group-teaser__navigate {
  border: 2px solid $active-color;
  padding: 10px;
  width: 2.5rem;
  height: 2.5rem;
  background: url("icons/angle-right.svg") no-repeat center;
  &--back {
    transform: rotate(180deg);
    margin-right: 1rem;
  }
}

.card__content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.anchor__container {
  position: relative;
  width: 100%;
  display: flex;
  min-height: rem-calc(250px);
  height: auto;

  @include breakpoint(medium) {
    min-height: rem-calc(320px);
  }

  &:hover {
    .anchor__content-wrapper {
      background-color: $blue;
    }
  }

  a {
    color: white !important;
  }
}

.anchor__teaser-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.anchor__teaser__placeholder {
  height: 100%;
  width: 100%;
  background-color: rgba(19, 92, 150, 0.2);
}

.anchor__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  background-color: $blue-alpha;
  color: $white;
  transition: background-color 0.35s;
  margin-left: auto;
  z-index: 10;

  @include breakpoint(medium) {
    padding: 4rem;
    flex: 0 0 50%;
  }
}

;@import "sass-embedded-legacy-load-done:124";