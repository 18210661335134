// Typography
// =================

.sfr-small {
  font-size: $footer-font;
}

.hyphens {
  hyphens: auto;
}

h1 {
  @include breakpoint(medium) {
    font-size: $h1-font-size;
  }
  font-size: $h2-font-size-small;
  line-height: normal;
  padding: 0 0 1rem 0;
  font-family: $font-main;
}
h2 {
  @include breakpoint(medium) {
    font-size: $h2-font-size;
  }
  font-size: $h2-font-size-small;
  line-height: normal;
  padding: 0 0 1rem 0;
  font-family: $font-main;
}
h3 {
  font-size: 17px;
  line-height: 24px;
  padding: 0 0 0.5rem 0;
  font-family: $font-main;
}
h4,
.h4 {
  font-size: $subtitle-font-size;
  line-height: normal;
  font-weight: bold;
  font-family: $font-main;
}
h5 {
  font-size: $subtitle-font-size-small;
  font-family: $font-main;
}
h6 {
  font-size: 12px;
  line-height: 18px;
  font-family: $font-main;
}
li,
a {
  font-family: $font-main;
  font-size: $main-font-size;
}
p {
  margin-bottom: 1rem;
}
p,
address,
time {
  margin-top: 0;
  font-size: $main-font-size;
  font-family: $font-main;
  line-height: 1.5;
  //margin-bottom: 1rem;
}

blockquote {
  margin: 0 0 10px 0;
  padding: 10px 20px;
  border-left: 5px solid #e2e2e2;
  font-size: 18px;
  line-height: 22px;
}

.sfr-bold {
  font-weight: bold;
}

// Typography Classes

.paragraph-title {
  font-size: $paragraph-title-small;
  font-family: $font-main;
  font-weight: bold;
  color: $black;
  margin-bottom: 0;
  @include breakpoint(medium up) {
    font-size: $paragraph-title;
  }

  &--margin {
    padding-bottom: 5px;
  }
}

.paragraph-title--white {
  font-family: $font-main;
  font-weight: bold;
  color: $white;
  font-size: $paragraph-title-white;
}

.paragraph-time,
.paragraph-address {
  font-size: $paragraph-title-small;
  font-family: $font-main;
}

.sfr-subtitle {
  @extend .paragraph-title;
  margin-bottom: 5px;
}

button {
  font-family: $font-main;
}

.text--margin {
  margin-bottom: 1rem;
}

.text--blue {
  color: $blue;
}

.text--bold {
  font-weight: bold;
}

.content-richtext {
  sub {
    font-size: 60%;
  }

  ul,
  ol {
    li:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  a:not(.no-decoration) {
    color: $blue;
    //@extend %topic-theme-font-color;
  }

  h3 {
    font-size: rem-calc(20px);
    line-height: 1.4;
  }

  h2,
  h3,
  h4 {
    &:first-child {
      margin-top: 0;
    }
    margin-top: $general-block-margin;
    margin-bottom: $general-block-margin;

    @include breakpoint(medium) {
      margin-top: $general-block-margin * 2;
    }

    &[style="text-align:center"] {
      max-width: 80%;
      margin: $general-block-margin * 2 auto;
      line-height: 1.4;

      @include breakpoint(medium) {
        margin: $general-block-margin * 2.5 auto;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:115";