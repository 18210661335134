.featured__title {
  display: flex;
  margin-bottom: 0;
  @include breakpoint(large) {
    margin-right: 60px;
  }

  .featured_event-icon {
    @include breakpoint(large) {
      margin-top: 10px;
      margin-right: 15px;
    }
    min-width: 44px;
    height: 44px;
    margin-bottom: auto;
    margin-right: 15px;
  }
}

.event__filter {
  @include breakpoint(large) {
    margin-right: 20px;
  }

  & p {
    font-weight: bold;
  }
}

.pagination__arrow {
  margin: auto;
  padding-top: 5px;
  list-style: none;
}

.pagination__item-active {
  margin-top: 1px;
  padding: 0;
  list-style: none;
  font-weight: normal;
}
.pagination__item {
  margin: auto;
  padding: 0;
  list-style: none;
  font-weight: normal;
}
.pagination__arrow--prev {
}
.archiv__pagination {
  width: 130px;
  margin: 0 auto;
  font-weight: bold;
}

.pagination--align {
  display: flex;
  justify-content: space-around;
}

.pagination--active {
  height: 25px;
  width: 25px;
  background: $active-color;
  border-radius: 50%;
  transform: translateY(8%);
  text-align: center;
}

.event__filter-buttons {
  display: flex;
  position: relative;
  font-family: $font-main;
}

.archive__button-group {
  margin-bottom: 20px;
  @include flexbox();
  @include flex-direction(column);
  .archive__button {
    padding: 5px 10px;
    text-align: left;
    //  height: 30px;
    width: 100%;
    background: $grey;
    margin-bottom: 10px;
    &:hover {
      background: darken($grey, 10%);
    }
  }
  .archive__button--active {
    position: relative;
    background: $active-color;
    @include flexbox();
    @include justify-content(space-between);
    &:hover {
      background: lighten($active-color, 10%);
    }
  }
}

.archive__button-clear {
  margin-top: 20px;
  font-weight: bold;
  height: auto;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  border: 2px solid $active-color;
  transition: 0.3s;
  font-size: 15px;
  padding: 5px 20px;
  &:hover {
    background-color: $active-color;
    color: $white;
  }
}

.archive--hide {
  display: none;
}

.archive__calender {
  padding: 5px 10px;
  text-align: left;
  height: 30px;
  width: 100%;
  background: $grey;
  margin-bottom: 10px;

  &:hover {
    background: darken($grey, 10%);
  }

  .archive__button-left {
    float: left;
    padding-top: 2px;
  }
  .archive__button-right {
    float: right;
    padding-top: 2px;
  }
}

.cal_head {
  text-align: center;
}

.event-detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.event-detail__image-container {
  flex: 1 0 10%;
  margin-bottom: 1rem;

  @include breakpoint(medium) {
    margin-bottom: 0;
  }

  img {
    width: 44px;
  }
}

.event-detail__content {
  flex: 1 1 90%;

  margin-left: 0.75rem;

  @include breakpoint(medium) {
    margin-left: 1.25rem;
  }
}

.event-detail__footer {
  display: flex;
  flex-direction: column;
  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.event-detail__address {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

;@import "sass-embedded-legacy-load-done:123";