$smedium: 460px;
$medium: 940px;
$large: 1024px;
$xlarge: 1200px;
$xxlarge: 1440px;

html {
  height: 100%;
  scroll-behavior: smooth;
}

[data-anchor] {
  scroll-margin-top: 6rem;
  @include breakpoint(large) {
    scroll-margin-top: 10rem;
  }
}

body {
  padding-top: $header-height - 0.375rem;
  margin: 0;
  min-height: 100%;
  height: 100%;
  box-sizing: border-box;
  font: 16px/1 sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  line-height: 1.4;
  font-family: $font-main;
  @include breakpoint(large) {
    padding-top: 0;
  }
}

.main-content {
  min-height: 100%;
  //margin-bottom: -50px;

  .standard-section:first-of-type,
  .snippet:first-of-type {
    margin-top: 0;
    padding-top: 0;
  }

  .section-anchor:not(:first-of-type) {
    margin-top: $general-margin * 2;

    @include breakpoint(medium) {
      margin-top: $general-margin * 3;
    }
  }
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.w-hidden-main {
  display: none;
}

.grid-container--margin {
  margin-bottom: $general-margin * 1.5;
  @include breakpoint(medium) {
    margin-bottom: $general-margin * 2;
  }
}
.grid-container--margin-x2 {
  margin-bottom: $general-margin * 3;
  @include breakpoint(medium) {
    margin-bottom: $general-margin * 4;
  }
}

.grid-100 {
  height: 100%;
}

@media screen and (max-width: $large) {
  .w-container {
    max-width: $medium;
  }
  .w-hidden-main {
    display: inherit;
  }
  .w-hidden-medium {
    display: none;
  }
}

@media screen and (max-width: $medium) {
  .w-hidden-main {
    display: inherit;
  }
  .w-hidden-medium {
    display: block;
  }
  .w-hidden-small {
    display: none;
  }
}

@media screen and (max-width: $smedium) {
  .w-container {
  }
  .w-hidden-main {
    display: inherit;
  }
  .w-hidden-medium {
    display: inherit;
  }
  .w-hidden-small {
    display: inherit;
  }
  .w-hidden-tiny {
    display: none;
  }
}

.medium-medium-8 {
  width: 83.33333%;
  margin: 0 auto;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

//limit iframe width in html content
.content-html {
  iframe {
    max-width: 100% !important;
  }
}

.description-section {
  background-color: $grey;
  padding: 10px 0;

  @include breakpoint(small only) {
    padding: 10px 0;
  }
  a {
    color: $blue;
  }
  li {
    margin-bottom: 0.5rem;
  }
}

.standard-section {
  padding: 10px 0;
  @include breakpoint(small only) {
    padding: 10px 0;
  }
  a {
    color: $blue;
  }
  li {
    margin-bottom: 0.5rem;
  }
}

.feed-section {
  border-top: 2px solid $active-color;

  img {
    margin-bottom: 1rem;
  }
}

.grey-section {
  background-color: $grey;
}

//.nl-section {
//  height: 100vh;
//}

.no-margin {
  margin: 0;
}

.sfr-icon {
  margin-top: 20px;
  margin-left: 15px;
  @include breakpoint(large) {
    margin-top: 20px;
    margin-right: 0;
    margin-left: 0;
  }
  /* transition: 0.7s;
    &:hover {
      transition: 0.7s;
      transform: rotate(45deg);
    }*/
}

.sfr-icon-top {
  position: relative;
  left: -25px;
  img {
    height: 45px;
    width: 45px;
  }
}

a {
  &:hover .icon--small {
    transform: rotate(-45deg);
  }
}
.icon--small {
  @include breakpoint(large) {
    margin-right: 33px;
    left: 0;
  }
  @include breakpoint(small only) {
    margin-right: 20px;
    margin-left: 5px;
  }
  margin-right: 20px;
  position: relative;
  transition: 0.2s ease-in-out;
  width: 45px;
  height: 45px;
}

.event-container {
  margin-bottom: 10px;
  padding-top: 10px;
  display: flex;

  .event-container__figure {
    margin-left: 0.1rem;
  }

  figure {
    flex: 3;
  }
  @include breakpoint(small only) {
    // margin: 20px 0;
    &.event_detail {
      flex-direction: column;
    }

    .icon--small {
      margin-left: 0;
      //margin-right: auto;
      margin-bottom: 20px;
    }
    figure {
      margin-bottom: 20px;
    }
  }
}

.event_detail__subtitle {
  display: block;
  margin-bottom: 1rem;
}

.past-events {
  margin-top: 2rem;
  display: flex;

  .button--right {
    height: auto;
    margin: 0 auto;
  }
}
.sfr-login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
  margin: 10px auto;
  input {
    margin: 5px;
    text-align: center;
    height: 47px;
    border: 0;
    background: $white;
    font-size: 17px;
  }
  select {
    margin: 5px;
    text-align: center;
    height: 35px;
    border: 0;
    background: $white;
    font-size: 17px;
  }
}
.innogroup,
.sfr-padding {
  h1 {
    @include breakpoint(medium) {
      font-size: $h1-font-size;
    }
    font-size: 25px;
  }
  h2 {
    @include breakpoint(medium) {
      font-size: 26px;
    }
    font-size: 22px;
  }
}
.sfr-download-icon {
  @extend .sfr-download-icon;
  margin: 0 0 0 10px;
}

.sfr-feed-download {
  //border-top: 0.03rem solid $black;
  width: 100%;
  display: block;
  padding-top: 20px;
  margin-bottom: 4rem;
  a {
    padding-bottom: 5px;
    clear: both;
    float: right;
    vertical-align: bottom;
  }
}
.inno-wrapper {
  display: flex;
  flex-direction: column;
  @include breakpoint(large) {
    flex-direction: row;

    .sfr-hr:first-child {
      display: none;
    }
  }
}
//.inno-wrapper.grid-container {
//  max-width: 95rem;
//}
.innogroup {
  flex: 2;
}
.no-content {
  flex: 1;
}
.event-wrapper {
  flex: 1;
  //margin: 0 auto;
}

.download__feed {
  .download-title__wrapper {
    display: flex;
  }
  .download-title {
    margin-bottom: 1rem;
  }
}

.download__main {
  a {
    color: $black;

    .sfr-download-icon {
      margin-right: 1rem;
    }
  }
}

.sfr-full-width {
  width: 100%;

  .content-richtext:first-of-type {
    padding-bottom: 10px;
    //border-bottom: 0.03rem solid $black;
  }
}

.content-richtext {
  a {
    color: $blue;
  }
}

.sfr-document-icon {
  height: 30px;
  transition: none;
  margin-right: 1rem;
  transform: translateY(-15%);
}

.sfr-button {
  font-weight: bold;
  height: auto;
  width: auto;
  text-align: center;
  vertical-align: middle;
  margin: 10px auto;
  border: 2px solid $active-color;
  transition: 0.3s;
  font-size: 15px;
  padding: 5px 20px;

  &:hover {
    background-color: $active-color;
    color: $white;
  }

  &.sfr-button--blue {
    display: flex;
    align-content: center;
    transition: none;
    border: 2px solid $blue;

    &:hover {
      background-color: $blue;

      .icon {
        filter: brightness(10);
      }
    }
  }

  &.sfr-button--white {
    display: flex;
    align-content: center;
    transition: none;
    border: 2px solid $white;
    color: $white;

    &:hover {
      background-color: $white;
      color: $black;

      .icon {
        filter: brightness(0);
      }
    }
  }

  &.sfr-button--left {
    align-content: flex-start;
    margin-left: 0;
  }
}

.button--right {
  @extend .sfr-button;
  margin-left: 0;
  margin-top: auto;
  margin-right: auto;
  padding: 5px 30px;
  height: 3rem;
  @include breakpoint(medium) {
    margin-left: auto;
    margin-right: 0;
    padding: 5px 30px;
  }
  p {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.sfr-padding--extra {
  padding-top: calc(4.5 * (1vw + 1vh - 1vmin));
  padding-bottom: calc(4.5 * (1vw + 1vh - 1vmin));
  @include breakpoint(large) {
    padding-top: 60px;
    padding-bottom: 120px;
  }
}

.snippet {
  padding-top: calc(3.5 * (1vw + 1vh - 1vmin));
  padding-bottom: calc(3.5 * (1vw + 1vh - 1vmin));
  @include breakpoint(large) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.sfr-padding {
  padding-top: calc(3.5 * (1vw + 1vh - 1vmin));
  padding-bottom: calc(3.5 * (1vw + 1vh - 1vmin));
  @include breakpoint(large) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.padding--small {
  padding-top: calc(1 * (1vw + 1vh - 1vmin));
  padding-bottom: calc(1 * (1vw + 1vh - 1vmin));
  @include breakpoint(large) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.padding--bottom {
  padding-bottom: calc(1 * (1vw + 1vh - 1vmin));
  @include breakpoint(large) {
    padding-bottom: 20px;
  }
}

.padding--bottom {
  padding-top: calc(1 * (1vw + 1vh - 1vmin));
  @include breakpoint(large) {
    padding-top: 20px;
  }
}

.sfr-hr {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 0;
  width: 100%;
  border-bottom: 1px solid $black;
  @include breakpoint(small only) {
    //display: none;
  }
}

.hr--small {
  margin-bottom: 10px;
  padding-top: 10px;
  height: 5px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  border-bottom: 1px solid $black;
  @include breakpoint(small only) {
    display: none;
  }
}

// remove foundation unnecessary general margin
.responsive-embed,
.flex-video {
  margin-bottom: 0;
}

//404
.page-not-found p {
  margin-bottom: 4rem;
}
.page-not-found__button {
  margin-left: 2rem;
}

.medium-12-padding {
  @include breakpoint(medium) {
    padding-left: 15px;
  }
}

.template-title {
  margin: 20px 0;
  padding: 0;
}
.top {
  margin-bottom: 1.5rem;
}
.news__header {
  display: flex;
}
.speech {
  background: url("icons/sprechblase.png") no-repeat center;
  width: 2rem;
  margin-right: 20px;
  background-size: contain;
  @include breakpoint(small only) {
    display: none;
  }
}
.richtext__header {
  overflow: hidden;
  margin: 0;
  // max-height: 320px;
  width: 100%;
  .richtext__header-image {
    width: 100%;
  }
}

.richtext__image-text {
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 1rem;
}

.richtext__image {
  @include breakpoint(small only) {
    margin-top: 10px;
  }
}

.richtext__image-right {
  @include breakpoint(small only) {
    margin-top: 30px;
  }
}

.richtext__right {
  margin-left: 2rem;
  @include breakpoint(small only) {
    padding-top: calc(1 * (1vw + 1vh - 1vmin));
    padding-bottom: calc(1 * (1vw + 1vh - 1vmin));
  }
}

.richtext__left {
  margin-right: 2rem;
  @include breakpoint(small only) {
    padding-top: calc(1 * (1vw + 1vh - 1vmin));
    padding-bottom: calc(1 * (1vw + 1vh - 1vmin));
  }
}

// event pagination

.document__pagination {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  .paging ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    li {
      a {
        padding: 0 0.5rem;
        cursor: pointer;
      }
    }
  }

  .document__list-active {
    height: 25px;
    width: 25px;
    background: $active-color;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    display: flex;
  }

  .document__button-left {
    padding-top: 4px;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  .document__button-right {
    padding-top: 4px;
    margin-left: 0.5rem;
    cursor: pointer;
  }
}

.pagination {
  margin: 0;
  padding: 0;
}

.document__pagination-item {
  font-weight: normal;
}

.align--right {
  margin-left: auto;
}

.image_upload {
  width: 100%;
}

// Large
.image-size--4 {
  width: 100%;
}
// Medium
.image-size--3 {
  width: 75%;
}
// Small
.image-size--2 {
  width: 50%;
}
// Tiny
.image-size--1 {
  width: 35%;
}

.image-grid {
}

.innovationgroup__header {
}

.errorlist {
}
.error-links {
  margin-top: 3rem;
  display: flex;
}

.align-center--small {
  @include breakpoint(small only) {
    justify-content: center;
  }
}

.space--xl {
  margin-bottom: $general-margin;

  @include breakpoint(medium) {
    margin-bottom: $general-margin * 7;
  }
}

.space--xl-top {
  padding-top: $general-margin;

  @include breakpoint(medium) {
    padding-top: $general-margin * 7;
  }
}

;@import "sass-embedded-legacy-load-done:116";