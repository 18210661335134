// Testimonial Slider
// =================

.testimonial {
  background: $white;

  .testimonial__wrapper {
    overflow: hidden;
    position: relative;

    .testimonial__slider {
      display: flex;
      transition: transform 0.5s ease-in-out;
    }
  }
}

.testimonial__slide {
  display: flex;
  //flex: 0 1 25%;
  // flex-direction: row;
  background: $white;
  overflow: hidden;
  @include breakpoint(large up) {
    padding: 0 0.625rem;
  }

  .quation__image-container {
    max-height: 100%;
    flex: 0 1 50%;
    overflow: hidden;
  }

  .quotation__image {
    height: 100%;
  }

  .quotation__author {
    hyphens: auto;
  }

  @include breakpoint(small only) {
    flex-direction: column-reverse;

    .quation__image-container {
      height: 20rem;
      padding: 0;
      flex: none;
    }

    .quotation__image {
      height: 100%;
    }

    .quotation__author {
      margin-top: 0.625rem;
      padding: 0 1rem;
    }
  }

  .testimonial__text-wrapper {
    flex: 0 1 50%;
    font-size: $main-font-size;
    padding: 1.25rem 2.5rem;
    @include breakpoint(small only) {
      padding: 0.625rem 1.25rem;
    }

    .quotation__text {
      hyphens: auto;
      margin-bottom: 1.25rem;
    }
  }
}

.quotation__button {
  font-weight: bold;
  height: auto;
  text-align: center;
  vertical-align: middle;
  border: 2px solid $active-color;
  transition: 0.3s;
  font-size: 15px;
  padding: 0.3125rem 1.875rem;
  &:hover {
    background-color: $active-color;
    color: $white;
  }
}

.testimonial__slide:nth-child(odd) .testimonial__text-wrapper {
  border-bottom: 1px solid $black;
  border-top: 1px solid $black;
  .quotation-mark {
    background: url("icons/quotation-mark.svg") no-repeat center;
    width: 1.5em;
    height: 1.5rem;
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
    margin-left: -1.25rem;
    fill: $white;
    &--right {
      @extend .quotation-mark;
      margin-left: auto;
      margin-right: 1.25rem;
      margin-top: 0.5rem;
    }
  }
  @include breakpoint(small only) {
    border: none;
  }
}

.testimonial__slide:nth-child(even) .testimonial__text-wrapper {
  background: $blue;
  color: $white;
  .quotation-mark {
    background: url("icons/quotation-mark-white.svg") no-repeat center;
    width: 1.5rem;
    height: 1.5rem !important;
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
    margin-left: -1.25rem;
    fill: $white;
    &--right {
      @extend .quotation-mark;
      margin-left: auto;
      margin-right: 1.25rem;
      margin-top: 0.5rem;
    }
  }
  .quotation__button {
    font-weight: bold;
    height: auto;
    text-align: center;
    vertical-align: middle;
    border: 2px solid $white;
    transition: 0.3s;
    font-size: $main-font-size-small;
    padding: 0.3125rem 1.875rem;
    &:hover {
      background-color: $white;
      color: $black;
    }
  }

  @include breakpoint(small only) {
    background: none;
    color: $black;
    .quotation__button {
      border: 2px solid $active-color;
      &:hover {
        background-color: $active-color;
        color: $white;
      }
    }

    .quotation-mark {
      background: url("icons/quotation-mark.svg") no-repeat center;
      width: 1.5rem;
      height: 1.5rem;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      margin-left: -1.25rem;
      fill: $white;
      &--right {
        width: 1.5rem;
        height: auto;
        margin-left: auto;
        margin-right: 1.25rem;
        margin-top: 0;
        fill: $white;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:125";