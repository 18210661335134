// Event Detail (Feed)
// =================

.event__header {
}

.event__back {
  font-size: $back-button;
  display: flex;
  align-items: center;
  i {
    margin-right: 0.5rem;
  }
}

.event-title {
  padding-bottom: 5px;
}

.event__subtitle {
  display: block;
  margin-bottom: 1rem;
}

.innovationgroup__wrapper {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.innovationgroup__image-container {
  flex: 1 1 10%;

  img {
    display: block;
    width: 44px;
    margin-bottom: 1rem;
    @include breakpoint(medium) {
      margin: 0 auto;
    }
  }
}

.innovationgroup__header {
  flex: 1 1 90%;

  @include breakpoint(medium) {
    margin-left: 1.25rem;
  }
}

;@import "sass-embedded-legacy-load-done:120";