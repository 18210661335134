@use "sass:math";

.content-header__container {
  display: flex;
  justify-content: flex-start;
  position: relative;
  //width: 90vw;
  color: #fff;
  padding-top: rem-calc(35px);
  max-height: 65vh;
  height: 40rem;

  .grid-container {
    width: $global-width;
  }

  @include breakpoint(large) {
    padding-top: rem-calc(35px);
    max-height: 70vh;
    height: 42rem;
  }

  &.content-header__container--empty {
    display: none;
  }
}

.content-header__badge {
  position: absolute;
  max-height: 35vh;
  max-width: 35vh;
  height: auto;
  width: 18rem;
  right: 0;
  top: 30%;
  transform: translate(0, -50%);
  z-index: 1000;

  @include breakpoint(medium) {
    top: 45%;
    max-height: 45vh;
    max-width: 55vh;
    height: auto;
    width: 26rem;
  }

  @include breakpoint(large) {
    top: 60%;
    max-height: 50vh;
    max-width: 50vh;
    width: 30rem;
    height: auto;
  }
}

.content-header__top-title {
  font-size: rem-calc(17px);

  @include breakpoint(medium) {
    font-size: rem-calc(24px);
  }
}

.content-header__image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.content-header__image-copyright {
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
  transform: rotate(-90deg) translateX(100%);
  transform-origin: right;
  @include breakpoint(medium) {
    right: 2rem;
    transform: none;
  }
}

.content-header__content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
  height: 100%;
  margin-top: auto;
  z-index: 2;
}

.content-header__content {
  padding: $general-margin;
  background-color: $blue-alpha;
  transition: background-color 0.3s;

  @include breakpoint(medium) {
    padding: $general-margin $general-margin * 6 $general-margin $general-margin;
  }
  &:hover {
    background-color: $blue;
  }
}

.subheader__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $general-margin;
  padding-bottom: math.div($general-block-margin, 2);
  margin-bottom: $general-block-margin;
  @include breakpoint(medium) {
    margin-top: $general-margin * 2;
    margin-bottom: $general-margin;
  }
}

.responsive-image {
  margin: 0;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.responsive-image__img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}

.image {
  display: block;
  width: 100%;
}

;@import "sass-embedded-legacy-load-done:119";