$font-main: "Noto Sans", sans-serif;

$header-height: rem-calc(81);
$sticky-header-height: rem-calc(55);
$nav-bp: medium;
$main: #363636;
$brand-color: #ff3f5e;
$active-color: #ffb401;
$black: #222;
$white: #fff;
$grey: #f1f1f1;
$blue: #135c96;
$blue-alpha: rgba(19, 92, 150, 0.7);

$nav-toggle-transition: 0.25s ease-in-out;
$box-shadow-soft: 0 1px 5px -1px rgba(0, 0, 0, 0.38);
$box-shadow-hard: 0 2px 5px -1px rgba(0, 0, 0, 0.38);
$sticky-header-height-large: 4.75rem;

$icon-height: 1.5rem;
$icon-width: 2rem;
$icon-margin: 1rem;
$stroke-height: 0.25rem;
$stroke-width: 100%;

$header-height: rem-calc(70);
$nav-bp: medium;

// Typo

/* Desktop
-------------------------------------*/

$h1-font-size: 34px;
$h2-font-size: 30px;
$subtitle-font-size: 18px;
$main-font-size: 1.15rem;
$nav-font-size: 17px;
$nav-font-size-tiny: 10px;
$back-button: 18px;
$footer-font: 15px;
$paragraph-title: 17px;

/* Small
-------------------------------------*/

$paragraph-title-small: 15px;
$h2-font-size-small: 25px;
$subtitle-font-size-small: 17px;
$main-font-size-small: 15px;
$paragraph-title-white: 18px;

$general-margin: 2rem;
$general-block-margin: 1rem;

;@import "sass-embedded-legacy-load-done:112";