// Icons
// =================

$icon-size: 20px;

.angle-left {
  background: url("icons/angle-left.svg") no-repeat center;
  height: 1rem;
  width: 1rem;
  display: inherit;
}

.icon {
  display: inline-block;
  font-size: 0;
  width: $icon-size;
  height: $icon-size;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.7;
    transition: all 0.2s ease;
  }

  .navigation-meta__bottom & {
    margin-right: 0;
    margin-left: 0.5rem;
  }

  &.icon--large {
    width: $icon-size * 1.5;
    height: $icon-size * 1.5;
  }
}

.icon--linkedin {
  background-image: url("icons/linkedin-blue.svg");

  &.icon--invert {
    background-image: url("icons/linkedin-invert.svg");
  }
}

.icon--instagram {
  background-image: url("icons/instagram-blue.svg");

  &.icon--invert {
    background-image: url("icons/instagram-invert.svg");
  }
}

.icon--youtube {
  background-image: url("icons/youtube-blue.svg");

  &.icon--invert {
    background-image: url("icons/youtube-invert.svg");
  }
}

.icon--message {
  transition: none;
  background-image: url("icons/message-plain-blue.svg");

  .sfr-button--white & {
    background-image: url("icons/message-plain-white.svg");
  }
  &:hover {
    opacity: 1;
    transition: none;
  }
  &.icon--invert {
    background-image: url("icons/message-invert.svg");
  }
}

.linkedin {
  &:before {
    content: url("icons/linkedin.svg");
    margin-right: 0.5rem;

    .navigation-meta__bottom & {
      margin-right: 0;
    }
  }
  font-size: 0;
}

.cross {
  background: url("icons/cross.svg") no-repeat center;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 15px;
  font-weight: bold;
}

.event-cross {
  position: absolute;
  right: 0;
  margin-top: 3px;
  margin-right: 5px;
  background: url("icons/cross.svg") no-repeat center;
  border: 0 none;
  cursor: pointer;
  background: transparent;
  font-size: 15px;
  font-weight: bold;
}

.message {
  margin-top: 5px;
  margin-bottom: auto;
  background: url("icons/message.svg") no-repeat center;
  width: 2rem;
  height: 2rem;
  margin-right: 20px;
  @include breakpoint(small only) {
    display: none;
  }
}

.plus {
  margin-top: 5px;
  margin-bottom: auto;
  background: url("icons/plus.svg") no-repeat center;
  width: 2rem;
  height: 2rem;
  margin-right: 20px;

  @include breakpoint(small only) {
    display: none;
  }
}

;@import "sass-embedded-legacy-load-done:121";