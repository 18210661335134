.footer {
  font-size: $footer-font;

  padding: 30px 0;
  margin: auto;
  color: $white;
  background: $black;

  p,
  li,
  a {
    font-size: $footer-font;
  }

  .footer__logo-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    @include breakpoint(large) {
      flex-wrap: nowrap;
    }
  }

  .footer__logo {
    width: 100%;

    @include breakpoint(large) {
      width: 60%;
    }

    img {
      width: 60%;
      height: auto;
      @include breakpoint(large) {
        width: 100%;
      }
    }
  }

  .footer__supported {
    text-align: center;
    @include breakpoint(large) {
      text-align: left;
    }
  }

  .footer__logo2 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;

    @include breakpoint(large) {
      width: 40%;
      flex-flow: column;
      gap: 1rem;
    }

    > div {
      flex-basis: 50%;
      display: grid;
      align-items: center;
    }
  }

  .footer__address {
    height: 100%;
    text-align: center;
    @include breakpoint(large) {
      text-align: left;
    }
  }

  .footer__navigation {
    height: 100%;
  }

  .footer__social-profiles-wrapper {
    padding: 0;
    display: flex;
    align-content: center;
    align-items: center;
    // width: 15rem;
    margin-bottom: 1rem;
    gap: 0.75rem;
    justify-content: center;

    @include breakpoint(large) {
      width: 100%;

      justify-content: flex-end;
    }

    .icon--large,
    .sfr-button {
      margin: 0 !important;
    }

    .footer__social-profiles {
      list-style: none;
      .linkedin::before {
        content: url("icons/social_linkedin_negativ.svg");
      }
      .facebook::before {
        margin-left: 20px;
        content: url("icons/social_facebook_negativ.svg");
      }
    }
  }

  .footer__navigation-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;

    a {
      text-decoration: underline;
      font-weight: bold;
    }

    @include breakpoint(large) {
      flex-direction: column;
      align-items: flex-end;
      gap: 0.25rem;
    }

    li {
      list-style: none;
    }
  }

  .footer__social-profiles-wrapper,
  .footer__logo-wrapper {
    @include breakpoint(large down) {
      margin-bottom: 3rem;
    }
  }

  .footer__address {
    @include breakpoint(large down) {
      margin-bottom: 1.5rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:122";