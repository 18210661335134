.teaser {
  display: flex;
  margin-bottom: 3rem;
  //background-color: $grey;
  flex-direction: column;

  &:hover {
    .teaser__image {
      transform: scale(1.1);
    }
  }

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.teaser__image {
  transition: transform 0.35s;
}

.teasers {
  height: 100%;
  background-color: $grey;
  padding: 2rem;

  @include breakpoint(medium) {
    padding: 4rem;
  }
}

.teaser__wrapper {
}

.teaser__image-container {
  position: relative;
  width: 12rem;
  height: 12rem;
  overflow: hidden;
  border-radius: 50%;
  background-color: $blue-alpha;

  img {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.teaser__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  flex: 1 1 65%;

  @include breakpoint(medium) {
    padding: 2rem;
  }
}

;@import "sass-embedded-legacy-load-done:129";