// Newsletter & Member
// =================

/* Newsletter
-------------------------------------*/

.newsletter {
  height: auto;
  background: $grey;
  padding-bottom: 1.25rem;
  margin-top: 1.25rem;
  @include breakpoint(large up) {
    height: 18.75rem;
    margin-top: 0;
  }
  .newsletter__content {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1.375rem 1.25rem 5.625rem;
    @include breakpoint(small only) {
      padding: 2rem;
    }

    p {
      // padding-right: 2.375rem;
    }

    .newsletter__header {
      display: flex;
      position: relative;
      left: -55px;
      @include breakpoint(small only) {
        left: 0;
      }
    }
  }

  .sfr-button {
    @include breakpoint(large up) {
      margin-left: 0;
      margin-right: auto;
    }
    margin: 1rem 0;
    padding: 0.625rem 1.25rem;
  }
}

/* Member
-------------------------------------*/

.member {
  height: auto;
  padding-bottom: 1.25rem;
  margin-top: 1.25rem;
  background: lighten($active-color, 40%);
  @include breakpoint(large up) {
    margin-left: 0.625rem;
    margin-top: 0;
    height: 18.75rem;
  }
  .newsletter__content {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 2.375rem 1.25rem 5.625rem;

    @include breakpoint(small only) {
      padding: 2rem;
    }

    .newsletter__header {
      display: flex;
      position: relative;
      left: -52px;
      @include breakpoint(small only) {
        left: 0;
      }
    }
  }

  .sfr-button {
    @include breakpoint(large up) {
      margin-left: 0;
      margin-right: auto;
    }
    margin: 1rem 0;
    padding: 0.625rem 1.25rem;
  }
}

/* Mailchimp
-------------------------------------*/

.sfr-newsletter-form {
  @extend .sfr-login-form;
  margin-left: 0;
  margin-right: auto;
  max-width: 100%;
  input {
    margin-bottom: 0.3125rem;
    margin-left: 0;
    margin-right: 1.875rem;
    @include breakpoint(medium up) {
      display: block;
    }
    @include breakpoint(medium only) {
      max-width: 80%;
    }
    display: none;
  }

  .sfr-button {
    @include breakpoint(medium up) {
      margin-left: 0;
      margin-right: auto;
      max-width: 25rem;
    }
    margin: 1.25rem 0;
    padding: 0.625rem 1.25rem;
  }
}

;@import "sass-embedded-legacy-load-done:127";